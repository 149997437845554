import React from 'react';
import { createUseStyles } from 'react-jss';
import { Paper } from '../utility/paper';
import { ContactButton } from '../layout/contactButton';

const useStyles = createUseStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: [['var(--size-3x)', 'var(--font-size-xl)']],
    '& svg': {
      width: 'var(--font-size-xxl)',
      height: 'var(--font-size-xxl)'
    }
  },
  top: {
    '& > *': {
      margin: [[0, 0, 'var(--font-size-xl)', 0]]
    }
  },
  title: {
    fontSize: 'var(--font-size-md)',
    fontWeight: 700
  },
  text: {
    fontSize: 'var(--font-size-md)',
    fontWeight: 500
  }
}));

export const IndividualServiceCard = ({ cardTitle, cardText, icon }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.card} as="section">
      <div className={classes.top}>
        <div>{icon}</div>
        <h2 className={classes.title}>{cardTitle}</h2>
        <p className={classes.text}>{cardText}</p>
      </div>
      <div>
        <ContactButton />
      </div>
    </Paper>
  );
};
