import { Header } from './header';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    ...theme.minSidePadding
  },
  heading: {
    maxWidth: 'var(--layout-width)',
    margin: [[0, 'auto']],
    fontSize: 'var(--font-size-xxl)',
    fontWeight: 600,
    color: 'white',
    width: '100%',
    overflowWrap: 'break-word'
  },
  subheading: {
    fontSize: 'var(--font-size-md)'
  },
  image: {
    maxHeight: '20vh'
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    image: {
      minHeight: 'calc(var(--font-size-xxl) * 9)'
    },
    container: {
      justifyContent: 'end'
    },
    heading: {
      paddingBottom: 'var(--font-size-xxl)'
    }
  }
}));

export const SubpageHeader = ({ title }) => {
  const classes = useStyles();
  return (
    <Header imageClass={classes.image}>
      <div className={classes.container}>
        <h1 className={classes.heading}>{title}</h1>
      </div>
    </Header>
  );
};
