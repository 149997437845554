import React from 'react';
import { createUseStyles } from 'react-jss';
import { ContactButton } from '../layout/contactButton';
import { PictureText } from './pictureText';

const useStyles = createUseStyles((theme) => ({
  header: {
    marginBottom: 'var(--font-size-lg)'
  },
  heading: {
    fontWeight: 600,
    fontSize: 'var(--font-size-xxl)'
  },
  subheading: {
    fontWeight: 500,
    fontSize: 'var(--font-size-lg)'
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  content: {
    fontSize: 'var(--font-size-md)'
  },
  button: {},
  currentStatistics: {
    fontWeight: 600,
    fontSize: 'var(--font-size-md)'
  }
}));

export const PictureOffer = ({
  heading,
  subheading,
  html,
  followedByStatistics,
  ...other
}) => {
  const classes = useStyles();
  return (
    <PictureText descriptionClass={classes.description} {...other}>
      <div>
        {heading && (
          <header className={classes.header}>
            <h2 className={classes.heading}>{heading}</h2>
            {subheading && <p className={classes.subheading}>{subheading}</p>}
          </header>
        )}
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      {followedByStatistics ? (
        <p className={classes.currentStatistics}>Obecne statystyki strony:</p>
      ) : (
        <ContactButton className={classes.button} />
      )}
    </PictureText>
  );
};
