import React from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imageContainer: {
    order: (props) => (props.imageRight ? 2 : 1),
    flex: [[0, 0, '48%']]
  },
  image: {
    borderRadius: 'var(--font-size-md)'
  },
  description: ({ imageRight }) => ({
    ...(imageRight
      ? {
          order: 1,
          paddingRight: 'var(--font-size-lg)'
        }
      : {
          order: 2,
          paddingLeft: 'var(--font-size-lg)'
        }),
    flex: [[0, 0, '48%']],
    padding: [['var(--font-size-lg)', '0']]
  }),
  [`@media (max-width: ${theme.breakPoints.mdMax})`]: {
    container: {
      flexDirection: 'column'
    },
    description: {
      '&&': {
        maxWidth: 550,
        margin: [[0, 'auto']],
        order: 2,
        paddingRight: 'var(--font-size-lg)',
        paddingLeft: 'var(--font-size-lg)'
      }
    },
    imageContainer: {
      order: 1
    },
    image: {
      maxWidth: 550,
      margin: [[0, 'auto']]
    }
  }
}));

export const PictureText = ({
  photo,
  imageClass,
  imageRight,
  descriptionClass,
  children,
  containerClass,
  containerStyle
}) => {
  const classes = useStyles({ imageRight });
  return (
    <div
      className={classnames(classes.container, containerClass)}
      style={containerStyle}
    >
      <div className={classes.imageContainer}>
        <Img
          fluid={photo.childImageSharp.fluid}
          className={classNames(classes.image, imageClass)}
        />
      </div>
      <div className={classnames(classes.description, descriptionClass)}>
        {children}
      </div>
    </div>
  );
};
