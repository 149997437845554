import { graphql } from 'gatsby';
import React from 'react';
import { Layout } from '../components/layout/layout';
import { SEO } from '../components/layout/seo';
import { useConfig } from '../hooks/useConfig';
import { createUseStyles } from 'react-jss';
import { SubpageHeader } from '../components/layout/subpageHeader';
import { Belt } from '../components/index-and-offer/belt';
import { IndividualServicesGrid } from '../components/index-and-offer/individualServicesGrid';
import { ServicesGrid } from '../components/index-and-offer/servicesGrid';
import { PictureOffer } from '../components/index-and-offer/pictureOffer';

const useStyles = createUseStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: 'var(--size-4x)'
    }
  },
  belt: {
    height: '12vh',
    margin: [['var(--size-4x)', 0]]
  },
  pictureTextContainer: {
    ...theme.minSidePadding
  },
  pictureText: {
    margin: [[0, 'auto']],
    maxWidth: 'var(--layout-width)'
  },
  image: {}
}));

export default function IndividualClients({
  data: {
    photos: { nodes }
  },
  location: { pathname }
}) {
  const {
    blogSEO: { title, description, keywords }
  } = useConfig();
  const classes = useStyles();
  return (
    <Layout
      slug={pathname}
      header={<SubpageHeader title="Oferta - klienci indywidualni" />}
      maxWidth={1400}
      contentClass={classes.content}
    >
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={pathname}
      />
      <Belt containerClass={classes.belt} />
      {nodes.map(({ frontmatter: { photo, title }, html }, i) => (
        <div
          className={classes.pictureTextContainer}
          style={{ order: i * 10 + 10 }}
          key={i}
        >
          <PictureOffer
            photo={photo}
            imageClass={classes.image}
            imageRight={i % 2 === 0}
            containerClass={classes.pictureText}
            html={html}
            heading={title}
          />
        </div>
      ))}
      <IndividualServicesGrid containerStyle={{ order: 15 }} />
      <ServicesGrid containerStyle={{ order: 100 }} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    photos: allMarkdownRemark(
      filter: { fields: { collection: { eq: "picture-text" } } }
      sort: { fields: fields___slug }
    ) {
      nodes {
        html
        frontmatter {
          title
          photo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
