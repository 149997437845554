import React from 'react';
import { createUseStyles } from 'react-jss';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import { IndividualServiceCard } from './individualServiceCard';
import AngielskiWIt from '../../assets/angielski-w-it.svg';
import BusinessEnglish from '../../assets/business-english.svg';
import EdukacjaZaGranica from '../../assets/edukacja-za-granica.svg';
import NaukaWymowy from '../../assets/nauka-wymowy.svg';

const useStyles = createUseStyles((theme) => ({
  container: {
    ...theme.minSidePadding,
    paddingTop: 'var(--size-4x)',
    paddingBottom: 'var(--size-4x)',
    background: theme.lightGrey,
    maxWidth: '100%'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    gap: 'var(--font-size-xl)',
    margin: [0, 'auto'],
    maxWidth: 'var(--layout-width)'
  },
  [`@media (max-width: ${theme.breakPoints.lgMax})`]: {
    grid: {}
  },
  [`@media (max-width: ${theme.breakPoints.mdMax})`]: {
    grid: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    grid: {
      gridTemplateColumns: `1fr`
    }
  }
}));

const icons = {
  'angielski-w-it': <AngielskiWIt />,
  'business-english': <BusinessEnglish />,
  'edukacja-za-granica': <EdukacjaZaGranica />,
  'nauka-wymowy': <NaukaWymowy />
};

export const IndividualServicesGrid = (props) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      cards: allOfferCardsYaml(sort: { fields: [position], order: ASC }) {
        nodes {
          cardText
          cardTitle
          icon
        }
      }
    }
  `);
  return (
    <div
      className={classNames(classes.container, props.className)}
      style={props.containerStyle}
    >
      <section className={classNames(classes.grid)}>
        {data.cards.nodes.map(({ icon, ...other }) => (
          <IndividualServiceCard key={icon} icon={icons[icon]} {...other} />
        ))}
      </section>
    </div>
  );
};
